<template>
  <v-container>
    <vue-editor
      v-model="content"
      :editor-toolbar="customToolbar"
      @blur="emitContent"
      @input="emitContent"
    />
  </v-container>
</template>
>

<script>
import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js'

export default {
  components: { VueEditor },
  props: {
    setTemplate: String,
    typename: String,
  },
  data() {
    return {
      type: '',
      content: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block'],
      ],
    }
  },
  watch: {
    setTemplate(val) {
      this.content = this.setTemplate.replace(
        /@MBTITYPE@/gi,
        `<strong>${this.typename}</strong>`
      )
    },
  },
  mounted() {
    this.content = this.setTemplate
  },
  methods: {
    emitContent() {
      // : typename이 SCORE 로 시작한다면 결과 유형이 있는지 확인하고 있다면 replace한다.
      if (this.typename.startsWith('SCORE')) {
        const index = this.typename.slice(5)

        if (this.$store.state.qna.creating.scoreResult.length > 0) {
          const replaceKeyword =
            this.$store.state.qna.creating.scoreResult.find(
              item => item.num == index
            )

          if (replaceKeyword.result) {
            this.content = this.content.replace(
              /@유형이름템플릿@/gi,
              `<strong>${replaceKeyword.result}</strong>`
            )
          }
        }

        // : store에 content 저장
        this.$store.dispatch('qna/actSetScoreResult', {
          num: Number(index),
          type: 'SCORE',
          content: this.content,
        })
      }

      if (this.typename == 'theme') {
        this.$emit('emitContent', this.content)
      }
      if (this.typename != 'temp') {
        const typename = 'MBTI_' + this.typename
        localStorage.setItem(typename, this.content)
      } else if (this.typename == 'temp') {
        const typename = 'MBTI_temp'
        localStorage.setItem(typename, this.content)
      } else {
        this.$emit('emitContent', this.content)
      }
      this.$store.dispatch('qna/actSetTemplate', this.content)
    },
  },
}
</script>
<style lang="css">
@import '~vue2-editor/dist/vue2-editor.css';

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
